<ng-container *transloco="let transloco">
  <nsa-modal>
    <ng-container modal-body>
      <p class="text-center">{{ transloco(!!data.message ? data.message : 'MODAL.CONFIRM.MESSAGE', data.translateParams) }}</p>
        @if(!!data?.showNote) {
          <div [formGroup]="form">
            <nsa-textarea
              nsaId="nsf-confirm-note-id"
              nsaName="nsf-confirm-note-name"
              class="text-center"
              size="md"
              label="LABEL.NOTE.TEXT"
              placeholder="LABEL.NOTE.PLACEHOLDER"
              formControlName="note">
            </nsa-textarea>
          </div>
        }
    </ng-container>

    <ng-container modal-footer>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center w-100">
        <nsa-button
          nsaId="nsf-abort-button"
          type='button'
          icon="x-lg"
          color="dark"
          iconColor="danger"
          [fullWidth]="true"
          (click)="onAbort()">
          {{ transloco('ACTION.ABORT') }}
        </nsa-button>

        <nsa-button
          nsaId="nsf-confirm-button"
          type='submit'
          icon="check-lg"
          color="dark"
          iconColor="success"
          [fullWidth]="true"
          (click)="onConfirm()">
          {{ transloco('ACTION.CONFIRM') }}
        </nsa-button>
      </div>
    </ng-container>
  </nsa-modal>
</ng-container>
